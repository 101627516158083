import { GET_DASHBOARD_INFO, GET_DASHBOARD_REPORTS, PROFILE, UPDATE_PROFILE, GET_PRACTITIONER_REPORTS, CLEAR_PRACTITIONER_REPORTS } from 'store/actions/profile';

const initialState = {
    isAuthenticated: false,
    user: null,
    dashboardInfo: {},
    dashboardReports: {},
    reportParams: {},
    practitionerReports: {},
    practitionerReportParams: {},
    loader:false,
};

//get profile
const getProfile = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

//update profile
const updateProfile = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    user:body,
  };
  return stateObj;
};

const getDashboardInfo = (state, payload) => {
  const { body } = payload;
  const stateObj = {
    ...state,
    isAuthenticated: true,
    dashboardInfo: body,
  };
  return stateObj;
}

const getDashboardReports = (state, payload) => {
  const { data, params } = payload;
  if(!data?.body) {
    return state;
  }

  let dashboardReports = {
    ...state.dashboardReports
  }

  if(params?.report) {
    dashboardReports[params?.report] = data?.body[params?.report];
    state.reportParams[params?.report] = params;
  } else {
    // if(dashboardReports["subscribers"]) {
    //   dashboardReports = {
    //     ...data?.body,
    //     subscribers: dashboardReports["subscribers"],

    //   }
    // } else {
    //   dashboardReports = data?.body;
    // }
    dashboardReports = data?.body;
  }

  const stateObj = {
    ...state,
    dashboardReports,
  };
  return stateObj;
}

const getPractitionerReports = (state, payload) => {
  const { data, params } = payload;
  if(!data?.body) {
    return state;
  }

  let practitionerReports = {
    ...state.practitionerReports
  }

  if(params?.report) {
    practitionerReports[params?.report] = data?.body[params?.report];
    state.practitionerReportParams[params?.report] = params;
  } else {
    practitionerReports = data?.body;
  }

  const stateObj = {
    ...state,
    practitionerReports,
  };
  return stateObj;
}

const clearPractitionerReports = (state) => {
  const stateObj = {
    ...state,
    practitionerReports: {},
  };
  return stateObj;
}

const Profile = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case PROFILE:
          return getProfile(state, payload);
        case UPDATE_PROFILE:
          return updateProfile(state, payload);
        case GET_DASHBOARD_INFO:
          return getDashboardInfo(state, payload);
        case GET_DASHBOARD_REPORTS:
          return getDashboardReports(state, payload);
        case GET_PRACTITIONER_REPORTS:
          return getPractitionerReports(state, payload);
        case CLEAR_PRACTITIONER_REPORTS:
          return clearPractitionerReports(state);
        default:
        return state;
    };
}
export default Profile;