import { forwardRef, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

const ReportDateRange = ({start, end, handleSelectDateRange, buttonClassName, ...props}) => {
  const [startDate, setStartDate] = useState(start || new Date());
  const [endDate, setEndDate] = useState(end || new Date());
  const [dateRangeChanged, setDateRangeChanged] = useState(false);

  useEffect(() => {
    let startDate = start;
    let endDate = end;

    if(!start && !end) {
      return; 
    }

    setStartDate(startDate);
    setEndDate(endDate);
  }, [start, end]);

  useEffect(() => {
    if(!dateRangeChanged || !startDate || !endDate) {
      return;
    }

    handleSelectDateRange(startDate, endDate);
    setDateRangeChanged(false);
  }, [JSON.stringify(startDate), JSON.stringify(endDate), dateRangeChanged, handleSelectDateRange]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end)
    setDateRangeChanged(true);
  };
  
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className }, ref) => (
      <Button
        variant="transparent"
        className={`${buttonClassName || "border p-0 px-2"}`}
        onClick={onClick}
        ref={ref}
        style={{ minWidth: "190px" }}
      >
        {value}
      </Button>
    ),
  );

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      customInput={<ExampleCustomInput />}
      selectsRange
      {...props}
    />
  );
}

export default ReportDateRange;