import React, { lazy } from "react";

//imported
import * as Path from "./paths";

//auth
import SignIn from "components/views/admin/auth/Login";
import ForgetPassword from "components/views/admin/forgot_password/ForgotPassword";
import ChangePassword from "components/views/admin/change_password/ChangePassword";
import ResetPassword from "components/views/admin/reset_password/ResetPassword";

//dashboard
import Dashboard from "components/views/admin/dashboard/Dashboard";
//profile
import Profile from "components/views/admin/profile/Profile";
//subscriptions
import SubscriptionList from "components/views/admin/subscription/List";
import SubscriptionAddEdit from "components/views/admin/subscription/AddEdit";
import SubscriptionView from "components/views/admin/subscription/View";

//categories
import CatgeoryList from "components/views/admin/category/List";
import CatgeoryAddEdit from "components/views/admin/category/AddEdit";
import CatgeoryView from "components/views/admin/category/View";

//subcategories
import SubCatgeoryList from "components/views/admin/subcategory/List";
import SubCatgeoryAddEdit from "components/views/admin/subcategory/AddEdit";
import SubCatgeoryView from "components/views/admin/subcategory/View";
//resource
import ResourceList from "components/views/admin/resources/List";
import ResourceAddEdit from "components/views/admin/resources/AddEdit";
import ResourceView from "components/views/admin/resources/View";

//FAQ
import FAQList from "components/views/admin/faq/List";
import FAQAddEdit from "components/views/admin/faq/AddEdit";
import FAQView from "components/views/admin/faq/View";

//Content pages
import ContentList from "components/views/admin/content_pages/List";
import ContentAddEdit from "components/views/admin/content_pages/AddEdit";

//Practitioner
import PractitionerList from "components/views/admin/practitioner/List";
import PractitionerAddEdit from "components/views/admin/practitioner/AddEdit";
import PractitionerView from "components/views/admin/practitioner/View";

//Practitioner
import ClientList from "components/views/admin/client/List";
import ClientView from "components/views/admin/client/View";

//Transaction
//Earning
import EarningList from "components/views/admin/earning/List";

//Subscription History
import SubscriptionHistoryList from "components/views/admin/subscription_history/List";

//System access
import SystemAccessList from "components/views/admin/system_access/List";
import SystemAccessAddEdit from "components/views/admin/system_access/AddEdit";
import SystemAccessView from "components/views/admin/system_access/View";

//Configuration
import ConfigurationList from "components/views/admin/configuration/List";
// Configuration instance permissions page
import ConfigurationPermission from "components/views/admin/configuration/Permissions/Index";

//Notification
import NotificationList from "components/views/admin/notification/List";
import NotificationAdd from "components/views/admin/notification/AddEdit";

//Client category form
import ClientCatgoryFormList from "components/views/admin/client_category_form/List";

//Email TEmplate
import EmailTemplate from "components/views/admin/EmailTemplate/Index";
import EditEmailTemplate from "components/views/admin/EmailTemplate/Edit/Index";

import HelpPages from "components/views/admin/help_pages/List";
import HelpPageAddEdit from "components/views/admin/help_pages/AddEdit";

// Form Management
import FormManagementListing from "components/views/admin/form_management/List";

// Banner Management
import BannerManagement from "components/views/admin/banner_management/List";
import BannerAddEdit from "components/views/admin/banner_management/AddEdit";

// Announcements
import Announcements from "components/views/admin/announcements/List";
import AnnouncementAddEdit from "components/views/admin/announcements/AddEdit";

// Email logs
import EmailLogs from "components/views/admin/email_logs/List";

//not found page
import NotFound from "components/NotFound";

// reporting
import Dashboards from "components/views/admin/dashboard/Dashboards";
import PractitionerReports from "components/views/admin/dashboard/PractitionerReports/Index";

const routes = [
  /******** login routes ***********/
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    // component: lazy(() => import('components/views/admin/modules/auth/Login'))
    component: SignIn,
  },
  /******** forgot password ***********/
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: ForgetPassword,
  },
  /******** reset password ***********/
  {
    path: Path.reset_password,
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  /******** change password ***********/
  {
    path: Path.change_password,
    exact: true,
    auth: true,
    component: ChangePassword,
  },
  /******** dashboard ***********/
  {
    path: Path.dashboard,
    exact: true,
    auth: true,
    // component: Dashboard,
    component: Dashboards,
  },
  /******** profile ***********/
  {
    path: Path.profile,
    exact: true,
    auth: true,
    component: Profile,
  },
  /******** subscriptions ***********/
  {
    path: Path.subscriptions,
    exact: true,
    auth: true,
    component: SubscriptionList,
  },
  {
    path: Path.subscription_add,
    exact: true,
    auth: true,
    component: SubscriptionAddEdit,
  },
  {
    path: Path.subscription_edit,
    exact: true,
    auth: true,
    component: SubscriptionAddEdit,
  },
  {
    path: Path.subscription_view,
    exact: true,
    auth: true,
    component: SubscriptionView,
  },
  /******** category ***********/
  {
    path: Path.category,
    exact: true,
    auth: true,
    component: CatgeoryList,
  },
  {
    path: Path.category_add,
    exact: true,
    auth: true,
    component: CatgeoryAddEdit,
  },
  {
    path: Path.category_edit,
    exact: true,
    auth: true,
    component: CatgeoryAddEdit,
  },
  {
    path: Path.category_view,
    exact: true,
    auth: true,
    component: CatgeoryView,
  },
  /******** subcategory ***********/
  {
    path: Path.subcategory,
    exact: true,
    auth: true,
    component: SubCatgeoryList,
  },
  {
    path: Path.subcategory_add,
    exact: true,
    auth: true,
    component: SubCatgeoryAddEdit,
  },
  {
    path: Path.subcategory_edit,
    exact: true,
    auth: true,
    component: SubCatgeoryAddEdit,
  },
  {
    path: Path.subcategory_view,
    exact: true,
    auth: true,
    component: SubCatgeoryView,
  },
  /******** resource ***********/
  {
    path: Path.resource,
    exact: true,
    auth: true,
    component: ResourceList,
  },
  {
    path: Path.resource_add,
    exact: true,
    auth: true,
    component: ResourceAddEdit,
  },
  {
    path: Path.resource_edit,
    exact: true,
    auth: true,
    component: ResourceAddEdit,
  },
  {
    path: Path.resource_view,
    exact: true,
    auth: true,
    component: ResourceView,
  },
  /******** FAQ ***********/
  {
    path: Path.faq,
    exact: true,
    auth: true,
    component: FAQList,
  },
  {
    path: Path.faq_add,
    exact: true,
    auth: true,
    component: FAQAddEdit,
  },
  {
    path: Path.faq_edit,
    exact: true,
    auth: true,
    component: FAQAddEdit,
  },
  {
    path: Path.faq_view,
    exact: true,
    auth: true,
    component: FAQView,
  },

  /**************Content pages*******/
  {
    path: Path.content_pages,
    exact: true,
    auth: true,
    component: ContentList,
  },
  {
    path: Path.content_add,
    exact: true,
    auth: true,
    component: ContentAddEdit,
  },
  {
    path: Path.content_edit,
    exact: true,
    auth: true,
    component: ContentAddEdit,
  },
  /******** Practitioner ***********/
  {
    path: Path.practitioner,
    exact: true,
    auth: true,
    component: PractitionerList,
  },
  {
    path: Path.practitioner_add,
    exact: true,
    auth: true,
    component: PractitionerAddEdit,
  },
  {
    path: Path.practitioner_edit,
    exact: true,
    auth: true,
    component: PractitionerAddEdit,
  },
  {
    path: Path.practitioner_view,
    exact: true,
    auth: true,
    component: PractitionerView,
  },
  /******clients*********/
  {
    path: Path.manageClients,
    exact: true,
    auth: true,
    component: ClientList,
  },
  {
    path: Path.client_view,
    exact: true,
    auth: true,
    component: ClientView,
  },
  /******** System Access ***********/
  {
    path: Path.system_access,
    exact: true,
    auth: true,
    component: SystemAccessList,
    permission: true
  },
  {
    path: Path.system_access_add,
    exact: true,
    auth: true,
    component: SystemAccessAddEdit,
    permission: true
  },
  {
    path: Path.system_access_edit,
    exact: true,
    auth: true,
    component: SystemAccessAddEdit,
    permission: true
  },
  {
    path: Path.system_access_view,
    exact: true,
    auth: true,
    component: SystemAccessView,
    permission: true
  },
  /******** Earning***********/
  {
    path: Path.earning,
    exact: true,
    auth: true,
    component: EarningList,
  },
  /******** Subscription History***********/
  {
    path: Path.subscription_history,
    exact: true,
    auth: true,
    component: SubscriptionHistoryList,
  },

  /******** Configuration***********/
  {
    path: Path.configuration,
    exact: true,
    auth: true,
    component: ConfigurationList,
  },
  {
    path: Path.instance_permissions,
    exact: true,
    auth: true,
    component: ConfigurationPermission,
  },

  /******** Notification***********/
  {
    path: Path.notification,
    exact: true,
    auth: true,
    component: NotificationList,
  },
  {
    path: Path.notification_add,
    exact: true,
    auth: true,
    component: NotificationAdd,
  },
  /******** Client category form***********/
  {
    path: Path.client_category_form,
    exact: true,
    auth: true,
    component: ClientCatgoryFormList,
  },

  /******** Emal Template***********/
  {
    path: Path.email_template,
    exact: true,
    auth: true,
    component: EmailTemplate,
  },
  {
    path: Path.email_template__edit,
    exact: true,
    auth: true,
    component: EditEmailTemplate,
  },
  {
    path: Path.email_template_edit,
    exact: true,
    auth: true,
    component: EditEmailTemplate,
  },
  /** Help pages **/
  {
    path: Path.help_pages,
    exact: true,
    auth: true,
    component: HelpPages,
  },
  {
    path: Path.help_pages_add,
    exact: true,
    auth: true,
    component: HelpPageAddEdit,
  },
  {
    path: Path.help_pages_edit_,
    exact: true,
    auth: true,
    component: HelpPageAddEdit,
  },
  /** Form Management **/
  {
    path: Path.form_management_listing,
    exact: true,
    auth: true,
    component: FormManagementListing,
  },

  /** Banner Management **/
  {
    path: Path.banner_management,
    exact: true,
    auth: true,
    component: BannerManagement,
  },
  {
    path: Path.banner_edit,
    exact: true,
    auth: true,
    component: BannerAddEdit,
  },

  /** Announcements **/
  {
    path: Path.announcements_management,
    exact: true,
    auth: true,
    component: Announcements,
  },
  {
    path: Path.announcement_edit,
    exact: true,
    auth: true,
    component: AnnouncementAddEdit,
  },
  {
    path: Path.announcement_add,
    exact: true,
    auth: true,
    component: AnnouncementAddEdit,
  },

  /** Email Logs **/
  {
    path: Path.email_logs_list,
    exact: true,
    auth: true,
    component: EmailLogs,
  },
  // Practitioner Reports
  {
    path: Path.practitioner_reports,
    exact: true,
    auth: true,
    component: PractitionerReports,
  },

  /******** not found page ***********/
  {
    path: "",
    exact: false,
    component: NotFound,
  },
];

export default routes;
