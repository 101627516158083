import { useEffect, useState } from "react";
import ReportDateRange from "./ReportDateRange"
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import { Form } from "react-bootstrap";

const TotalClientCount = ({ start, end, rangeType, isLoading, getReports, totalClient }) => {
  const report = "totalClient";
  const [startDate, setStartDate] = useState(start || new Date());
  const [endDate, setEndDate] = useState(end || new Date());
  const [dateRangeType, setDateRangeType] = useState("all");

  useEffect(() => {
    setDateRangeType(rangeType);
    setStartDate(start);
    setEndDate(end);
  }, [start, end, rangeType]);

  const handleSelectDateRange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getReports(report, dateRangeType, startDate, endDate);
  }

  const handleChangeDateRange = (e) => {
    const type = e.target.value;
    setDateRangeType(type);
    getReports(report, type, startDate, endDate);
  }

  return (
    <div className='dashboard-card'>
      <div className="dashboard-card-title d-block">
        <div>
          <div className="d-flex justify-content-between align-items-baseline mb-2">
            <h4 className='mb-0'>Total Clients</h4>
            <div className="d-flex align-items-baseline gap-10">
              <label>Date Range:</label>
              <div>
                <Form.Select
                  value={dateRangeType}
                  onChange={handleChangeDateRange}
                >
                  <option value="all">All Time</option>
                  <option value="custom">Custom</option>
                </Form.Select>
                {dateRangeType === "custom" && (
                  <div className="mt-2">
                    <ReportDateRange
                      start={startDate}
                      end={endDate}
                      report="totalClient"
                      handleSelectDateRange={handleSelectDateRange}
                      // handleChangeType={handleChangeType}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isLoading ? <LoadingSkeleton style={{ width: "125px" }} /> : <h3 className=''>{totalClient || 0}</h3>}
        </div>
      </div>
    </div>
  )
}

export default TotalClientCount;