import Http from './Http';
import { SUCCESS_MESSAGE, ERROR_MESSAGE } from "../common/constantMessage";
const abortControllers = new Map();

//Post
export function postData(url, data) {
  return new Promise((resolve, reject) => {
    Http.post(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}

//Get
export function getData(url, params, options = {}) {
  const { abortable = false, requestKey } = options;

  // If abortable, generate a unique key (default to URL if no key is provided)
  const uniqueKey = abortable ? requestKey || url : null;
  
  // Abort the previous request if it exists
  if (uniqueKey && abortControllers.has(uniqueKey)) {
    abortControllers.get(uniqueKey).abort();
  }

  // Create a new AbortController if needed
  const controller = new AbortController();
  if (uniqueKey) {
    abortControllers.set(uniqueKey, controller);
  }
  const { signal } = controller;

  return new Promise((resolve, reject) => {
    Http.get(url, { ...params, signal })
      .then((res) => {
        if (uniqueKey) abortControllers.delete(uniqueKey);
        resolve(res.data);
      })
      .catch((err) => {
        if (uniqueKey) abortControllers.delete(uniqueKey);

        if (err.name === "CanceledError") {
          console.log("Request aborted:", uniqueKey);
          return;
        }

        console.log(err, "=======================err");
        if (err?.response) {
          const { status, data } = err.response;
          reject({ status, data });
        } else {
          if (err && err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      });
  });
}

export function putData(url, data) {
  return new Promise((resolve, reject) => {
    Http.put(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}
//Delete
export function deleteData(url, data) {
  return new Promise((resolve, reject) => {
    Http.delete(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}
//for image
export function postMediaData(url, data) {
  return new Promise((resolve, reject) => {
    Http.post(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          // SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}

//for common
export function postData_(url, data) {
  return new Promise((resolve, reject) => {
    Http.post(url, data)
      .then((res) => {
        if (res.data && res.data.message) {
          // SUCCESS_MESSAGE(res.data.message);
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err, "=======================err");
        if (err.response) {
          const { status, data } = err.response;
          const res = { status, data };
          if (res.data && res.data.message) {
            ERROR_MESSAGE(res.data.message);
          }
          reject(res);
        } else {
          if (err.message) {
            ERROR_MESSAGE(err.message);
          }
          reject(err);
        }
      })
      .finally(() => {
        // You can uncomment these lines if needed
        // callback();
        // props.dispatch(actions.persist_store({ loader: false }));
      });
  });
}


