import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Form } from "react-bootstrap";
import ReportDateRange from "../ReportDateRange";

const SubscriptionPlanTypes = ({ start, end, rangeType, isLoading, getReports, data }) => {

    const report = "subscriptionReport";
    const [startDate, setStartDate] = useState(start || new Date());
    const [endDate, setEndDate] = useState(end || new Date());
    const [dateRangeType, setDateRangeType] = useState("all");

    useEffect(() => {
        setDateRangeType(rangeType);
        setStartDate(start);
        setEndDate(end);
    }, [start, end, rangeType]);

    const handleSelectDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        getReports(report, dateRangeType, startDate, endDate);
    }

    const handleChangeDateRangeType = (e) => {
        const type = e.target.value;
        setDateRangeType(type);
        getReports(report, type, startDate, endDate);
    }
    
    const { series, labels } = React.useMemo(() => {
        let output = {
            series: [],
            labels: [],
        }

        if(!data?.length) {
            return output;
        }

        data.forEach(plan => {
            output.labels.push(plan.package_name);
            output.series.push(plan.subscription_count);
        })
        
        return output;
    });

    const chartOptions = {
        series,
        options: {
            labels,
            chart: {
                type: 'pie',
            },
            colors: ['#0059b3', '#775DD0'],
            dataLabels: {
                enabled: false
            },
            legend: {
                position: "bottom"
            }
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         chart: {
            //             // width: 200,
            //         },
            //         legend: {
            //             position: 'bottom'
            //         }
            //     }
            // }]
        },


    }

    return (
        <div className='border bg-white p-3 rounded-3'>
                <h4 className='mb-2'>Subscription Plans</h4>
                <div className="d-flex justify-content-between align-items-baseline gap-10">
                <label>Date Range:</label>
                <div>
                <Form.Select
                    value={dateRangeType}
                    onChange={handleChangeDateRangeType}
                >
                    <option value="all">All Time</option>
                    <option value="custom">Custom</option>
                </Form.Select>
                {dateRangeType === "custom" && (
                    <div className="mt-2">
                        <ReportDateRange
                            start={startDate}
                            end={endDate}
                            // buttonClassName={"p-1 border bg-white"}
                            handleSelectDateRange={handleSelectDateRange}
                        />
                    </div>
                )}
                </div>
            </div>
            {!data?.length ? (
                <p>No data found</p>
            ):(
                <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="pie" />
            )}
        </div>
    )
}

export default SubscriptionPlanTypes