const { useState, useEffect } = require("react");
const { Form } = require("react-bootstrap");
const { default: ReportDateRange } = require("../../Component/ReportDateRange");
const { default: LoadingSkeleton } = require("components/shared/LoadingSkeleton/Index");

const SentNAQReport = ({ start, end, rangeType, isLoading, getReports, sentNAQsReport }) => {
  const report = "sentNAQsReport";
  const [startDate, setStartDate] = useState(start || new Date());
  const [endDate, setEndDate] = useState(end || new Date());
  const [dateRangeType, setDateRangeType] = useState("all");

  useEffect(() => {
      setDateRangeType(rangeType);
      setStartDate(start);
      setEndDate(end);
  }, [start, end, rangeType]);

  const handleSelectDateRange = (startDate, endDate) => {
      setStartDate(startDate);
      setEndDate(endDate);
      getReports(report, dateRangeType, startDate, endDate);
  }

  const handleChangeDateRange = (e) => {
      const type = e.target.value;
      setDateRangeType(type);
      getReports(report, type, startDate, endDate);
  }
  
  return (
    <div className='border bg-white rounded-3 p-3'>
      <div className="d-flex justify-content-between align-items-baseline">
        <h4 className='mb-0'>Sent NAQs Report</h4>
        <div className="d-flex justify-content-between align-items-baseline gap-10">
          <label>Date Range:</label>
          <div>
            <Form.Select
              value={dateRangeType}
              onChange={handleChangeDateRange}
            >
              <option value="all">All Time</option>
              <option value="custom">Custom</option>
            </Form.Select>
            {dateRangeType === "custom" && (
              <div className="mt-2">
                <ReportDateRange
                  start={startDate}
                  end={endDate}
                  // report="totalPractitioner"
                  handleSelectDateRange={handleSelectDateRange}
                // handleChangeType={handleChangeType}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex align-items-center" style={{ gap: 100 }}>
        <div className="">
          <h4 className='mb-2'>NAQ v1</h4>
          {isLoading ? <LoadingSkeleton /> : <h3 className='mb-0'>{sentNAQsReport?.NAQ_count || 0}</h3>}
        </div>
        <div className="">
          <h4 className='mb-2'>NAQ v2</h4>
          {isLoading ? <LoadingSkeleton /> : <h3 className='mb-0'>{sentNAQsReport?.NAQ2_count || 0}</h3>}
        </div>
      </div>
    </div>
  )
}

export default SentNAQReport;