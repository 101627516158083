import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import ReportDateRange from "../ReportDateRange";
import { Form } from "react-bootstrap";

const SalesChart = ({ start, end, rangeType, isLoading, getReports, data }) => {
    const report = "subscriptionSalesReport";
    const [startDate, setStartDate] = useState(start || new Date());
    const [endDate, setEndDate] = useState(end || new Date());
    const [dateRangeType, setDateRangeType] = useState("all");
    const [groupBy, setGroupBy] = useState("month");

    useEffect(() => {
        setDateRangeType(rangeType);
        setStartDate(start);
        setEndDate(end);
    }, [start, end, rangeType]);

    const handleSelectDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        getReports(report, dateRangeType, startDate, endDate, groupBy);
    }

    const handleChangeDateRange = (e) => {
        const type = e.target.value;
        setDateRangeType(type);
        getReports(report, type, startDate, endDate, groupBy);
    }

    const handleChangeGroupBy = (e) => {
        const groupBy = e.target.value;
        setGroupBy(groupBy);
        getReports(report, dateRangeType, startDate, endDate, groupBy);
    }

    const [dataset1, dataset2] = useMemo(() => {
        let output = [[], []];

        if (!data?.length) {
            return output;
        }

        data.forEach(item => {
            let x = new Date(item?.period).getTime();
            output[0].push({
                x,
                y: item?.count
            });

            output[1].push({
                x,
                y: item?.totalAmount
            });
        })

        return output;
    }, [data]);

    const chartData = {
        series: [
            {
                name: 'Total Subscriptions',
                data: dataset1,
            },
            {
                name: 'Total Amount',
                data: dataset2,
            },
        ],
        options: {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
            },
            colors: ['#99ccff', '#027af2'],
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 0,
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#0059b3',
                    },
                    offsetX: 0,
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            xaxis: {
                labels: {
                    formatter: function (val, timestamp) {
                        switch (groupBy) {
                            case "month":
                                return moment(val).format("MMM YYYY");
                            case "week":
                            case "day":
                            default:
                                return moment(val).format("DD MMM YYYY");
                        }
                    },
                },
            },
            tooltip: {
                shared: true,
            },
            legend: {
                show: false,
            },
        },
    };

    const salesReportAmount = useMemo(() => {
        let output = 0;

        if (!data?.length) {
            return output;
        }

        data.forEach(item => {
            output += Number(item?.totalAmount) || 0;
        })

        return output;
    }, [data]);
    return (
        <div className='border bg-white rounded-3 p-3'>
            <h4 className='mb-0'>Subscription Sales</h4>
            <div className="mt-3 d-flex justify-content-between align-items-baseline">
                <div className="d-flex align-items-center">
                    <label style={{ minWidth: "75px" }}>Group By:</label>
                    <select
                        className="form-select"
                        onChange={handleChangeGroupBy}
                    >
                        <option value={"day"}>Day</option>
                        <option value={"week"}>Week</option>
                        <option value={"month"} selected>Month</option>
                    </select>
                </div>
                <div className="d-flex align-items-baseline gap-10">
                    <label>Date Range:</label>
                    <div>
                        <Form.Select
                            value={dateRangeType}
                            onChange={handleChangeDateRange}
                        >
                            <option value="all">All Time</option>
                            <option value="custom">Custom</option>
                        </Form.Select>
                        {dateRangeType === "custom" && (
                            <div className="mt-2">
                                <ReportDateRange
                                    start={startDate}
                                    end={endDate}
                                    // report="totalPractitioner"
                                    handleSelectDateRange={handleSelectDateRange}
                                    // handleChangeType={handleChangeType}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='mt-3 d-flex align-items-start gap-3'>
                <h3 className='mb-0'>${salesReportAmount.toFixed(2) || "0.00"} USD</h3>
            </div>
            <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={250} />
        </div>
    )
}

export default SalesChart