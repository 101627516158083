import { postData, getData, putData, deleteData } from 'helpers/apiHelper'
import { PRACTITIONER_ADD_NOTES, PRACTITIONER_NOTES, PRACTITIONER_MY_CLIENT, PRACTITIONER_HISTORY, MAIN_PATH, PRACTITIONER_LIST, PRACTITIONER_ADD, PRACTITIONER_UPDATE, PRACTITIONER_VIEW, PRACTITIONER_DELETE, CSV_FILE_API, CSV_FILE_API_PRAC, CSV_FILE_UPDATE_PRAC, PRACT_RESEND_PWD_RESET_LINK, RESET_USER_PASSWORD, LOAD_SAMPLE_DATA } from './apiPath'
import { getPractitionerNotes, getPractitionerMyClint, getPractitionerHistory, getPractitioner, addPractitioner, viewPractitioner, updatePractitioner, deletePractitioner, clearPractitionerDetail, importCSVFile, importCSVFilePrac, importUpdateCSVFilePrac } from 'store/actions/practitioner';
import { pickBy } from "lodash";

//get
export function getPractitionerList(value, callback) {
  let { page = 1, limit = 10, search, status, sub_status, instance_type, startDate, endDate, package_type, orderBy, sortOrder } = value || {};
  let url = `${MAIN_PATH}${PRACTITIONER_LIST}`;
  // let url = `http://localhost:3000/STAGING/practitioner/list`;
  search = search !== undefined ? search : '';
  status = status !== undefined ? status : '';
  sub_status = sub_status !== undefined ? sub_status : '';
  instance_type = (instance_type && !isNaN(instance_type)) ? parseInt(instance_type) : '';
  let params = {
    page,
    limit,
    search,
    status,
    sub_status,
    instance_type,
    startDate,
    endDate,
    package_type,
    orderBy,
    sortOrder,
  }

  let queryString = new URLSearchParams(pickBy(params)).toString();
  url += `?${queryString}`;

  return (dispatch) => getData(url, value, {abortable: true, requestKey: "getPractitionerList"})
    .then((data) => {
      dispatch(getPractitioner(data, callback));
      return data;
    });
}
export function getPractitionerMyClientList(value, callback) {
  const { page, limit, id } = value || {};
  let url = `${MAIN_PATH}${PRACTITIONER_MY_CLIENT}${id}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getPractitionerMyClint(data, callback));
      return data;
    });
}
// get notes

export function getPractitionerNotesList(value, callback) {
  const { page, limit, id } = value || {};
  let url = `${MAIN_PATH}${PRACTITIONER_NOTES}`;
  if (page !== undefined && limit !== undefined) {
    url += `?user_id=${id}&${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getPractitionerNotes(data, callback));
      return data;
    });
}

//add
export function addPractitionerNotes(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${PRACTITIONER_ADD_NOTES}`, value)
    .then((data) => {
      dispatch(addPractitioner(data, callback));
      return data;
    });
}


// PRACTITIONER_MY_CLIENT
export function getPractitionerHistoryList(value, callback) {
  const { page, limit, id } = value || {};
  let url = `${MAIN_PATH}${PRACTITIONER_HISTORY}${id}`;
  if (page !== undefined && limit !== undefined) {
    url += `?page=${page}&limit=${limit}`;
  }
  return (dispatch) => getData(url, value)
    .then((data) => {
      dispatch(getPractitionerHistory(data, callback));
      return data;
    });
}

//add
export function addPractitionerData(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${PRACTITIONER_ADD}`, value)
    .then((data) => {
      dispatch(addPractitioner(data, callback));
      return data;
    });
}

//view
export function viewPractitionerData(value, callback) {
  let url = `${MAIN_PATH}${PRACTITIONER_VIEW}${value}`;
  // let url = `http://localhost:3000/dev/practitioner/detail/${value}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(viewPractitioner(data, callback));
      return data;
    });
}


//edit
export function updatePractitionerData(value, callback) {
  return (dispatch) => putData(`${MAIN_PATH}${PRACTITIONER_UPDATE}`, value)
    .then((data) => {
      dispatch(updatePractitioner(data, callback));
      return data;
    });
}


//delete
export function deletePractitionerData(value) {
  return (dispatch) => deleteData(`${MAIN_PATH}${PRACTITIONER_DELETE}${value}`)
    .then((data) => {
      dispatch(deletePractitioner(data));
      return data;
    });
}

export function clearPractitionerDetail_(data, callback) {
  return (dispatch) => {
    dispatch(clearPractitionerDetail(data, callback));
    return data;
  };
}
//import
export function importCSVFile_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CSV_FILE_API}`, value)
    .then((data) => {
      dispatch(importCSVFile(data, callback));
      return data;
    });
}

//import
export function importCSVFilePrac_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CSV_FILE_API_PRAC}`, value)
    .then((data) => {
      dispatch(importCSVFilePrac(data, callback));
      return data;
    });
}
//IMPORT UPDATE
export function importUpdateCSVFilePrac_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${CSV_FILE_UPDATE_PRAC}`, value)
    .then((data) => {
      dispatch(importUpdateCSVFilePrac(data, callback));
      return data;
    });
}


// Resend password reset link
export function resendPasswordResetLink(practID, callback) {
  return postData(`${MAIN_PATH}${PRACT_RESEND_PWD_RESET_LINK}${practID}`)
} 

// reset user password
export function resetUserPassword(userID) {
  let url = `${MAIN_PATH}${RESET_USER_PASSWORD}${userID}`;
  // let url = `http://localhost:3000/dev/practitioner/reset-password/${userID}`;
  return postData(url)
}


// load sample data
export function loadSampleDataIntoUser(userID) {
  let url = `${MAIN_PATH}${LOAD_SAMPLE_DATA}${userID}`;
  // let url = `http://localhost:3000/dev/practitioner/reset-password/${userID}`;
  return postData(url)
}