//action types
export const PROFILE = 'PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_DASHBOARD_INFO = 'GET_DASHBOARD_INFO';
export const GET_DASHBOARD_REPORTS = 'GET_DASHBOARD_REPORTS';
export const GET_PRACTITIONER_REPORTS = "GET_PRACTITIONER_REPORTS";
export const CLEAR_PRACTITIONER_REPORTS = "CLEAR_PRACTITIONER_REPORTS";

//get profile
export function getProfile(payload) {
  return {
    type: PROFILE,
    payload,
  };
}
//update profile
export function updateProfile(payload) {
  return {
    type: UPDATE_PROFILE,
    payload
  };
}
  
export function getDashboardInfo(payload) {
  return {
    type: GET_DASHBOARD_INFO,
    payload
  };
}

export function getDashboardReports(payload) {
  return {
    type: GET_DASHBOARD_REPORTS,
    payload
  };
}

export function getPractitionerReports(payload) {
  return {
    type: GET_PRACTITIONER_REPORTS,
    payload
  };
}

export function clearPractitionerReports() {
  return {
    type: CLEAR_PRACTITIONER_REPORTS
  };
}