import {postData, getData} from 'helpers/apiHelper'
import { DASHBOARD_INFO, DASHBOARD_REPORTS, MAIN_PATH, PROFILE, UPDATE_PROFILE, PRACTITIONER_REPORTS } from './apiPath'
import { getDashboardInfo, getDashboardReports, getProfile, updateProfile, getPractitionerReports} from 'store/actions/profile';
import { generateCustomPath } from "helpers/common";
import { pickBy } from "lodash";
//get profile
export function profile(value) {
  return (dispatch) => getData(`${MAIN_PATH}${PROFILE}`)
    .then((data) => {
      dispatch(getProfile(data));
      return data;
    });
}

//update profile
export function updateProfile_(value) {
  return (dispatch) => postData(`${MAIN_PATH}${UPDATE_PROFILE}`, value)
    .then((data) => {
      dispatch(updateProfile(data));
      return data;
    });
}

export function dashboardInfo(value) {
  return (dispatch) => getData(`${MAIN_PATH}${DASHBOARD_INFO}`, value)
    .then((data) => {
      dispatch(getDashboardInfo(data));
      return data;
    });
}

// new dashboard reports
export function dashboardReports(payload) {
  let queryString = new URLSearchParams(pickBy(payload))
  return (dispatch) => getData(`${MAIN_PATH}${DASHBOARD_REPORTS}?${queryString}`)
    .then((data) => {
      dispatch(getDashboardReports({ data, params: payload }));
      return data;
    });
}

// practitioner reports
export function practitionerReports(payload) {
  let { practitionerId, values } = payload
  let queryString = new URLSearchParams(pickBy(values))
  return (dispatch) => getData(`${MAIN_PATH}${generateCustomPath(PRACTITIONER_REPORTS, { id: practitionerId })}?${queryString}`)
    .then((data) => {
      dispatch(getPractitionerReports({ data, params: values }));
      return data;
    });
}