import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import "../dashboard/dashboard.css"
import SalesChart from './Component/charts/SalesChart'
import StudentPractitionerAccountStats from './Component/charts/StudentPractitionerAccountStats'
import SubscriptionPlanTypes from './Component/charts/SubscriptionPlanTypes'
import * as actions from "store/actions";
import { connect } from "react-redux"
import { dashboardReports } from "store/services/profileService"
import PractitionerStats from "./Component/PractitionersStats"
import ReportDateRange from "./Component/ReportDateRange"
import moment from "moment"
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index"
import TotalPractitionerCount from "./Component/TotalPractitionersCount"
import TotalClientCount from "./Component/TotalClientCount"


const Dashboards = ({
  dispatch,
  totalActiveClient,
  totalActivePractitioner,
  totalClient,
  totalPractitioner,
  subscribers,
  studentsStats,
  subscriptionSales,
  subscriptionSalesReport,
  subscriptionReport,
  reportParams
}) => {
  // const [startDate, setStartDate] = useState(moment().startOf("month").toDate());
  // const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeType, setDateRangeType] = useState("all");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getDashboardReports(null, dateRangeType, startDate, endDate);
  }, [startDate, endDate, dateRangeType]);

  const getDashboardReports = async (report, dateRangeType = "all", startDate, endDate, groupBy, sortConfig) => {
    try {
      let payload = {}

      if(dateRangeType === "custom") {
        payload.startDate = startDate ? new Date(startDate).toISOString() : null;
        payload.endDate = endDate ? new Date(endDate).toISOString() : null;
      }

      if(report) {
        payload.report = report;
      }

      if(groupBy) {
        payload.groupBy = groupBy;
      }

      if(sortConfig) {
        payload.orderBy = sortConfig.orderBy;
        payload.sortOrder = sortConfig.sortOrder;
      }

      setLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(dashboardReports(payload));
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    } catch (err) {
      console.log("Error fetching dashboard data", err);
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    }
  }

  // const handleSelectDateRange = (report, startDate, endDate) => {
  //   const groupBy = reportParams[report]?.groupBy;
  //   getDashboardReports(report, "custom", startDate, endDate, groupBy);
  // }

  const handleGlobalDateRangeChange = (startDate, endDate) => {
    // handleSelectDateRange(report, startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const handleChangeGroupBy = (groupBy, report) => {
    const dateRange = reportParams[report] || { startDate, endDate };
    let dateRangeType = "custom";
    if(!startDate || !endDate) {
      dateRangeType = "all";
    }
    getDashboardReports("subscriptionSalesReport", dateRangeType, dateRange.startDate, dateRange.endDate, groupBy);
  }

  const handleChangeReportDateRangeType = (type, report) => {
    const dateRange = reportParams[report] || { startDate, endDate };
    getDashboardReports("subscriptionSalesReport", type, dateRange.startDate, dateRange.endDate, null);
  }

  const handleChangeType = (report, type) => {
    console.log("handleChangeType");
    getDashboardReports(report, type);
  }

  return (
    <section className="reporting-dashboard">
      <Row className='align-items-center'>
        <Col lg="6">
          <h2>Dashboard</h2>
        </Col>
        <Col lg="6 d-flex justify-content-end align-items-center gap-2">
          {/* <div className='form-control d-flex rounded' style={{ width: "unset" }}>
            <svg height="20" width="20" className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-1pi2lzz" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SearchRoundedIcon"><path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0s.41-1.08 0-1.49zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"></path></svg>
            <input className='w-100 border-0 pl-2' placeholder='search...' type="text" style={{ outline: "none" }} />
          </div> */}
          <label>Date Range:</label>
          <div>
            <Form.Select
              value={dateRangeType}
              onChange={(e) => {
                setDateRangeType(e.target.value);
              }}
            >
              <option value="all">All Time</option>
              <option value="custom">Custom</option>
            </Form.Select>
          </div>
          {dateRangeType === "custom" && (
            <div className="bg-white">
              <ReportDateRange
                start={startDate}
                end={endDate}
                handleSelectDateRange={handleGlobalDateRangeChange}
                buttonClassName="border"
              />
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt-4 align-items-center">
        <Col lg="4">
          <div className='dashboard-card'>
            <div className='dashboard-card-title'>
              <div>
                <h4 className='mb-2'>Active Practitioners <span className="text-muted fw-normal text-body text-small ms-1">Last 15 days</span></h4>
                {isLoading ? <LoadingSkeleton /> : <h3 className=''>{totalActivePractitioner || 0}</h3> }
                {/* <p className='mb-0'>Last 30 days</p> */}
              </div>
              {/* <div className='plus'>
                +25%
              </div> */}
            </div>
          </div>
        </Col>
        <Col lg="4">
          <div className='dashboard-card'>
            <div className="dashboard-card-title">
              <div>
                <h4 className='mb-2'>Active Clients <span className="text-muted fw-normal text-body text-small ms-1">Last 15 days</span></h4>
                {isLoading ? <LoadingSkeleton /> : <h3 className=''>{totalActiveClient || 0}</h3> }
                {/* <p className='mb-0'>Last 30 days</p> */}
              </div>
              {/* <div className='minus'>
                +25%
              </div> */}
            </div>
          </div>
        </Col>
        <Col lg="4">
          <div className='dashboard-card'>
            <div className="dashboard-card-title">
              <div>
                <h4 className='mb-2'>Subscription Sales</h4>
                {isLoading ? <LoadingSkeleton /> : <h3 className=''>${subscriptionSales || "0.00"} USD</h3> }
                {/* <p className='mb-0'>Last 30 days</p> */}
              </div>
              {/* <div className='minus'>
                +25%
              </div> */}
            </div>
            {/* <ConversionsChart /> */}
          </div>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col lg="6">
          <TotalPractitionerCount
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            totalPractitioner={totalPractitioner}
          />
        </Col>
        <Col lg="6">
          <TotalClientCount
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            totalClient={totalClient}
          />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col lg="6">
          <SalesChart
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            data={subscriptionSalesReport}
          />
        </Col>
        <Col lg="6">
          <StudentPractitionerAccountStats
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            data={studentsStats}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="8">
          <PractitionerStats
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            data={subscribers}
          />
        </Col>
        <Col sm="4">
          <SubscriptionPlanTypes
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getDashboardReports}
            isLoading={isLoading}
            data={subscriptionReport}
          />
        </Col>
      </Row>
    </section>
  )
}

const mapStateToProps = (state) => {
  let {
    totalActiveClient,
    totalActivePractitioner,
    totalClient,
    totalPractitioner,
    subscriptionSales,
    studentsStats,
    subscribers,
    subscriptionSalesReport,
    subscriptionReport,
  } = state.Profile.dashboardReports;

  let reportParams = state.Profile.reportParams;


  return {
    totalActiveClient,
    totalActivePractitioner,
    totalClient,
    totalPractitioner,
    subscriptionSales,
    studentsStats,
    subscribers,
    subscriptionSalesReport,
    subscriptionReport,
    reportParams,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);
