import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import "../../dashboard/dashboard.css"
import * as actions from "store/actions";
import { connect } from "react-redux"
import { practitionerReports } from "store/services/profileService"
import ReportDateRange from "./../Component/ReportDateRange"
import moment from "moment"
import { useParams, Link, useLocation } from "react-router-dom"
import { clearPractitionerReports } from "store/actions/profile";
import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import { dashboard } from "routes/paths";
import SentNAQReport from "./Components/SentNAQReport";

const PractitionerReports = ({
  dispatch,
  activeClient,
  sentNAQsReport,
  thirdPartyConnections,
  reportParams
}) => {
  const { id: practitionerId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchStartDate = searchParams.get("startDate");
  const searchEndDate = searchParams.get("endDate");
  const searchDateRangeType = searchParams.get("dateRange");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeType, setDateRangeType] = useState("all");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getPractitionerReports(null, dateRangeType, startDate, endDate);
    return () => {
      dispatch(clearPractitionerReports());
    }
  }, [startDate, dateRangeType, endDate]);

  useEffect(() => {
    if(searchStartDate) setStartDate(new Date(searchStartDate));
    if(searchEndDate) setEndDate(new Date(searchEndDate));
    if(searchDateRangeType) setDateRangeType(searchDateRangeType);
  }, 
  [
    searchStartDate,
    searchEndDate,
    searchDateRangeType
  ])

  const getPractitionerReports = async (report, dateRangeType = "all", startDate, endDate, groupBy, sortConfig) => {
    try {
      let values = {}

      if (dateRangeType === "custom") {
        values.startDate = startDate ? new Date(startDate).toISOString() : null;
        values.endDate = endDate ? new Date(endDate).toISOString() : null;
      }

      if (report) {
        values.report = report;
      }

      if (groupBy) {
        values.groupBy = groupBy;
      }

      if (sortConfig) {
        values.orderBy = sortConfig.orderBy;
        values.sortOrder = sortConfig.sortOrder;
      }

      let payload = {
        practitionerId,
        values
      }

      setLoading(true);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(practitionerReports(payload));
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    } catch (err) {
      console.log("Error fetching dashboard data", err);
      dispatch(actions.persist_store({ loader: false }));
      setLoading(false);
    }
  }

  // const handleSelectDateRange = (report, startDate, endDate) => {
  //   const groupBy = reportParams[report]?.groupBy;
  //   getPractitionerReports(report, dateRangeType, startDate, endDate, groupBy);
  // }

  const handleGlobalDateRangeChange = (startDate, endDate) => {
    // handleSelectDateRange(report, startDate, endDate);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const getAllTimeData = () => {

  }

  return (
    <section className="reporting-dashboard">
      <Row className=''>
        <Col lg="6">
          <Link
            to={dashboard}
          >
            Dashboard
          </Link>{" "}
          &gt; <span style={{ color: "#000", fontWeight: "600" }}>Practitioner Reports</span></Col>
        <Col lg="6 d-flex justify-content-end align-items-center gap-2">
          <label>Date Range:</label>
          <div>
            <Form.Select
              value={dateRangeType}
              onChange={(e) => {
                setDateRangeType(e.target.value);
              }}
            >
              <option value="all">All Time</option>
              <option value="custom">Custom</option>
            </Form.Select>
          </div>
          {dateRangeType === "custom" && (
            <div className="bg-white">
              <ReportDateRange
                start={startDate}
                end={endDate}
                handleSelectDateRange={handleGlobalDateRangeChange}
                buttonClassName="border"
              />
            </div>
          )}
        </Col>
      </Row>
      <div className='mb-3'>
        <h2>Overview</h2>
      </div>
      <Row>
        <Col lg="2">
          <div className='dashboard-card'>
            <div className='dashboard-card-title'>
              <div>
                <h4 className='mb-2'>Active Clients</h4>
                {isLoading ? <LoadingSkeleton /> : <h3 className=''>{activeClient || 0}</h3>}

                {/* <p className='mb-0'>Last 30 days</p> */}
              </div>
              {/* <div className='plus'>
                +25%
              </div> */}
            </div>
            {/* <UsersChart /> */}
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="6">
          <SentNAQReport
            start={startDate}
            end={endDate}
            rangeType={dateRangeType}
            getReports={getPractitionerReports}
            isLoading={isLoading}
            sentNAQsReport={sentNAQsReport}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="6">
          <div className='border bg-white rounded-3 p-3'>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className='mb-0'>Third Party Connections</h4>
            </div>
            <div className="third-party-connections table-responsive">
              {isLoading ? (
                <div className="mt-4">
                  <LoadingSkeleton
                    itemCount={4}
                  />
                </div>
              ) : (
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="fw-bold" width={"30%"}>Stripe</td>
                      <td>
                        <ConnectionStatus
                          connected={thirdPartyConnections?.stripe}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold" width={"30%"}>Google Calendar</td>
                      <td>
                        <ConnectionStatus
                          connected={thirdPartyConnections?.google_calendar}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold" width={"30%"}>Practice Better</td>
                      <td>
                        <ConnectionStatus
                          connected={thirdPartyConnections?.practice_better}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-bold" width={"30%"}>Full Script</td>
                      <td>
                        <ConnectionStatus
                          connected={thirdPartyConnections?.full_script}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

const ConnectionStatus = ({ connected }) => {
  return (
    <>
      <OverlayTrigger
        placement={"right"}
        overlay={
          <Tooltip>
            {connected === "yes" ? "Connected" : "Not connected"}    
          </Tooltip>
        }
      >
        <span className="cursor-help">
          {connected === "yes" ? (
            <i className="fas fa-check-circle text-success"></i>
          ):(
            <i class="fas fa-times text-danger"></i>
          )}
        </span>
      </OverlayTrigger>
    </>
  )
}

const mapStateToProps = (state) => {
  let {
    activeClient,
    sentNAQsReport,
    thirdPartyConnections,
  } = state.Profile.practitionerReports;

  let reportParams = state.Profile.practitionerReportParams;


  return {
    activeClient,
    sentNAQsReport,
    thirdPartyConnections,
    reportParams
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(PractitionerReports);