import LoadingSkeleton from "components/shared/LoadingSkeleton/Index";
import { formatText } from "helpers/common";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory, generatePath, Link } from "react-router-dom";
import { practitioner, practitioner_reports } from "routes/paths";
import ReportDateRange from "./ReportDateRange";
import { orderBy, pickBy } from "lodash";

const PractitionerStats = ({ start, end, rangeType, isLoading, getReports, data }) => {
  const report = "subscribers";
  const history = useHistory();
  const [startDate, setStartDate] = useState(start || new Date());
  const [endDate, setEndDate] = useState(end || new Date());
  const [dateRangeType, setDateRangeType] = useState("all");
  const [sortConfig, setSortConfig] = useState({ orderBy: "client_count", sortOrder: "desc" });

  useEffect(() => {
    setDateRangeType(rangeType);
    setStartDate(start);
    setEndDate(end);
  }, [start, end, rangeType]);

  const viewAllLink = useMemo(() => {
    let params = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      dateRange: dateRangeType
    }

    let queryString = new URLSearchParams(pickBy(params)).toString();
    return `${practitioner}?${queryString}`;
  }, [startDate, endDate, dateRangeType])

  const handleSelectDateRange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getReports(report, dateRangeType, startDate, endDate);
  }

  const handleChangeDateRange = (e) => {
    const type = e.target.value;
    setDateRangeType(type);
    getReports(report, type, startDate, endDate);
  }

  const handleRedirect = (practId) => {
    let params = {
      startDate: startDate ? startDate?.toISOString() : null,
      endDate: startDate ? endDate?.toISOString() : null,
      dateRange: dateRangeType
    }
    let queryString = new URLSearchParams(pickBy(params));

    history.push(`${generatePath(practitioner_reports, { id: practId })}?${queryString}`);
  }

  const handleSort = (orderBy) => {
    const sort = {
      orderBy,
      sortOrder: sortConfig.orderBy === orderBy && sortConfig.sortOrder === "asc" ? "desc" : "asc",
    }
    setSortConfig(sort);
    getReports(report, dateRangeType, startDate, endDate, null, sort);
  };

  return (
    <div className="">
      <div className="mb-3 d-flex align-items-baseline justify-content-between">
        <h2>Practitioners</h2>
        <div className="d-inline">
          <div className="d-flex justify-content-between align-items-baseline gap-10">
            <label>Date Range:</label>
            <div>
              <Form.Select
                value={dateRangeType}
                onChange={handleChangeDateRange}
              >
                <option value="all">All Time</option>
                <option value="custom">Custom</option>
              </Form.Select>
            </div>
            {dateRangeType === "custom" && (
              <ReportDateRange
                start={startDate}
                end={endDate}
                buttonClassName={"p-1 border bg-white"}
                handleSelectDateRange={handleSelectDateRange}
              // handleChangeType={handleChangeType}
              />
            )}
          </div>
        </div>
      </div>
      <div className="table-responsive rounded-3 border bg-white">
        <table className="w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th className="cursor-pointer d-flex align-items-center" onClick={() => handleSort("client_count")}>
                <span className="me-2">Client Count</span>
                {sortConfig.orderBy === "client_count" ? (
                  sortConfig.sortOrder === "asc" ? <i class="fas fa-sort-up"></i> : <i class="fas fa-sort-down"></i>
                ) : null}
              </th>
              <th>Free Account</th>
              <th>Plan Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6} className="text-center"><LoadingSkeleton /></td>
              </tr>
            ) : (
              data?.length > 0 ? (
                data.map((pract, index) => (
                  <tr className="hover-row" onClick={() => handleRedirect(pract.id)}>
                    <td>{pract.first_name} {pract.last_name}</td>
                    <td>{pract.email}</td>
                    <td>{pract.client_count || "--"}</td>
                    <td>{formatText(pract.is_free_account) || "--"}</td>
                    <td>
                      <span >
                        {formatText(pract.plan_status) || "--"}
                      </span>
                    </td>
                    <td>
                      <i className="fas fa-chart-line"></i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Records Found
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end p-3">
        <Link
          to={viewAllLink}
          className="themeClr"
        >
          View All
        </Link>
      </div>
    </div>
  )
}

export default PractitionerStats;